import { createRouter, createWebHashHistory /* createWebHistory */ } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'wrapper',
    component: () => import('../views/WrapperView.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/annual-meeting',
        name: 'annual-meeting',
        component: () => import('../views/AnnualMeeting.vue')
      },
      {
        path: '/members',
        name: 'members',
        component: () => import('../views/MembersView.vue')
      },
      {
        path: '/ebook',
        name: 'ebook',
        component: () => import('../views/EBookView.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(), // ,createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== 'home') {
      return {
        top: 0
      }
    }
  }
})
export default router
